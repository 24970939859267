<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterAuditTrailTable
                    class="mr-2"
                    style="width:300px"
                    v-model:value="state.params.table"/>
                <FilterAuditTrailUser
                    class="mr-2"
                    style="width:300px"
                    v-model:value="state.params.user"/>
            </div>
            <div class="col-md-12 mt-2">
                <ASelect
                    class="mr-2"
                    v-model:value="state.params.action"
                    placeholder="Pilih Action"
                    allow-clear
                    style="width:300px"
                    :options="state.listActions"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.url"
                    :params="state.params"
                    namefile="Laporan-Audit-Trail"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.url"
                :params="state.params"
                :columns="state.columns"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue'
import TableStandard from '@/components/Molecules/TableStandard'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FilterAuditTrailTable from '@/components/filter/FilterAuditTrailTable'
import FilterAuditTrailUser from '@/components/filter/FilterAuditTrailUser'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        TableStandard,
        DownloadExcel,
        FilterAuditTrailTable,
        FilterAuditTrailUser,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'Tanggal',
                    dataIndex: 'time',
                },
                {
                    title: 'Table',
                    dataIndex: 'table',
                },
                {
                    title: 'Raw ID',
                    dataIndex: 'row_id',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                },
                {
                    title: 'Nama',
                    dataIndex: 'nama',
                },
                {
                    title: 'Before',
                    dataIndex: 'before',
                    customRender: ({ text }) => JSON.stringify(text),
                    width: 350,
                },
                {
                    title: 'After',
                    dataIndex: 'after',
                    customRender: ({ text }) => JSON.stringify(text),
                    width: 350,
                },
                {
                    title: 'Nama User',
                    dataIndex: 'nama_user',
                },
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Email User',
                    dataIndex: 'email_user',
                },
            ],
            url: '/api/report/metabase-v2/audit-trail',
            isFetching: false,
            params: {
                q: '',
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                action: null,
                table: [],
                user: [],
                page: 1,
                "per-page": 10,
            },
            listActions: [
                {
                    label: 'Insert',
                    value: 'insert',
                },
                {
                    label: 'Update',
                    value: 'update',
                },
                {
                    label: 'Delete',
                    value: 'delete',
                },
            ],
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const btnFetchData = async () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            state.params = pickBy(params)

            state.isFetching = true
            await nextTick()
            state.isFetching = false
        }

        // handle vue
        onMounted(() => {
            btnFetchData()
        })

        return {
            btnFetchData,
            state,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
        }
    },
})
</script>
